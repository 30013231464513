<template>
  <div class="wap-auth">
    <headerBar :title="$t('you-xiang-ren-zheng')" @back="back"></headerBar>

    <div class="main wap-page-form">
      <div class="form-item">
        <div class="label">{{ $t('you-xiang-0') }}</div>
        <div class="input">
          <Field
            v-model="form.email"
            :placeholder="$t('qing-shu-ru-you-xiang-9')"
          >
          </Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('yan-zheng-ma-0') }}</div>
        <div class="input">
          <Field
            v-model="form.realName"
            :placeholder="$t('qing-shu-ru-yan-zheng-ma-5')"
          >
            <template #button>
              <div class="blue link">{{ $t('fa-song-yan-zheng-ma') }}</div>
            </template>
          </Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('deng-lu-mi-ma') }}</div>
        <div class="input">
          <Field
            v-model="form.password"
            :type="showPassword ? 'text' : 'password'"
            :placeholder="$t('qing-shu-ru-deng-lu-mi-ma-3')"
          >
            <template #button>
              <div class="right-icon" @click="showPassword = !showPassword">
                <img :src="eyeUrl" alt="" v-if="!showPassword" />
                <img :src="eyeUrl2" alt="" v-else />
              </div>
            </template>
          </Field>
        </div>
      </div>

      <div class="form-btn">
        <Button class="submit-btn">{{ $t('ren-zheng') }}</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, DatetimePicker, Popup, Switch as vanSwitch, Field } from 'vant'
import headerBar from '@/components/header'
import moment from 'moment'
export default {
  components: {
    Button,
    headerBar,
    DatetimePicker,
    vanSwitch,
    Popup,
    Field
  },
  data() {
    return {
      avatar: '',
      form: {
        phone: '',
        phonePre: '+86'
      },
      showDialog: false,
      showPassword: false,
      eyeUrl: require('@/assets/imgs/icon-eye-black.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2-black.png')
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    }
  },
  mounted() {},
  methods: {
    back() {
      this.$router.go(-1)
    },
    save() {
      this.$store.state.avatar = this.avatar
      this.back()
    },
    changeHeader(i) {
      this.avatar = i
    },
    changeCountry() {
      this.showDialog = true
    }
  }
}
</script>